<template>
    <div>
        <div style="margin: 10px 0">
            <el-input class="ml-5" style="width: 200px"  placeholder="请输入商品名称" suffix-icon="el-icon-info" v-model="name"></el-input>
            <el-input class="ml-5" style="width: 200px"  placeholder="用户名" suffix-icon="el-icon-position" v-model="username"></el-input>
            <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
            <el-button class="ml-5" type="warning" @click="reset">重置</el-button>
        </div>
        <el-table :data="tableData" stripe :header-cell-class-name="'headerBg'">
            <el-table-column prop="no" label="订单号" width="200"></el-table-column>
            <el-table-column prop="username" label="用户名" width="100"></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="time" label="下单时间"></el-table-column>
            <el-table-column prop="state" label="支付状态" width="100"></el-table-column>
            <el-table-column prop="total" label="订单总价" ></el-table-column>
            <el-table-column prop="paymentTime" label="支付时间" ></el-table-column>
            <el-table-column prop="alipayNo" label="Paypal订单号" ></el-table-column>
            <el-table-column prop="returnTime" label="退款时间"></el-table-column>
            <el-table-column label="操作"  width="300" align="center">
                <template slot-scope="scope">
                    <!--<el-button type="primary" @click="payInsurance(scope.row)" :disabled="scope.row.state !== '待支付'"><i class="el-icon-edit"></i>支 付</el-button>-->
                    <el-button type="danger" @click="refundOrder(scope.row)" :disabled="scope.row.state !== '已支付'">退款</el-button>
                    <el-popconfirm
                        class="ml-5"
                        confirm-button-text='确定'
                        cancel-button-text='我再想想'
                        icon="el-icon-info"
                        icon-color="red"
                        title="您确定删除吗？"
                        @confirm="del(scope.row.id)"
                    >
                        <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <div style="padding: 10px 0">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-sizes="this.pageSizes"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>


<script>
import insurance from "@/views/Insurance.vue";
import {serverIp} from "../../public/config";

export default {
    name: "Orders",
    data() {
        return {
            username: [],
            tableData: [],
            name: '',
            types: '',
            price: null,
            multipleSelection: [],
            pageNum: 1,
            pageSize: 10,
            total: 0,
            pageSizes: [5,10,12],
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
            insurance: [],
        }
    },
    created() {
        this.load()
    },
    methods: {
        payInsurance(row){
            //得到一个url ，就是支付宝支付界面的url
            window.open(`http://${serverIp}:8088/alipay/pay?subject=${row.name}&traceNo=${row.no}&totalAmount=${row.total}`);
        },
        refundOrder(row) {
            this.$confirm('此操作将进行退款?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.request({
                    url: '/refundOrder',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: {
                        orderId: row.alipayNo,
                        price: row.total,
                        invoiceId: row.no
                    }
                }).then((res)=> {
                    this.$message.success("退款成功")
                    this.load()
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消退款'
                });          
            });
        },
        load() {
            this.request.get("/orders/page", {
                params: {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    username: this.username,
                    name: this.name
                }
            }).then(res => {
                this.tableData = res.data.records
                this.total = res.data.total

            })
        },

        reset(){
            this.name = ""
            this.username = ""
            this.load()
        },
        del(id) {
            this.request.delete("/orders/" + id).then(res => {
                if (res.code === '200') {
                    this.$message.success("删除成功")
                    this.load()
                } else {
                    this.$message.error("删除失败")
                }
            })
        },
        handleSizeChange(pageSize) {
            console.log(pageSize)
            this.pageSize = pageSize
            this.load()
        },
        handleCurrentChange(pageNum) {
            console.log(pageNum)
            this.pageNum = pageNum
            this.load()
        },
    }
}
</script>

<style >
.dialog{
    border-radius: 10px
}
</style>


<!--
<script>
import insurance from "@/views/Insurance.vue";

export default {
    name: "Order",
    data() {
        return {
            findInsurance:[],
            form:{},
            name: '',
            types: '',
            price: null,
            description: '',
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
            insurance: [],
            vis: false,
        }
    },
    created() {
        this.load()
    },
    methods: {
        load (){
            this.request.get('insurance/findInsurance/'  + this.user.id ).then(r => {
                this.findInsurance = r.data
                console.log(r.data)
                if (r.code === '200'){
                    this.vis = true
                }else {
                    this.$message.error("错误")
                }
            })
        },
        del(insuranceId){
            this.request.delete('insurance/deleteOrder/' + this.user.id + "/" + insuranceId).then(res => {
                console.log(res.data)
                if (res.code === '200'){
                    this.$message.success("删除成功！")
                    this.load()
                }else {
                    this.$message.error("删除失败")
                }
            })
        },
    }
}
</script>

<style scoped>
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
</style>
-->
